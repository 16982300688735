import { db } from '@/config/firebase';
import _ from 'lodash';


export default {
	namespace: true,

	state: {
		assetHistories: {}
	},

	mutations: {
		SET_ASSET_HISTORIES(state, assetHistories) {
			state.assetHistories = assetHistories
		}
	},

	actions: {
		async getAssetHistories({
			commit,
			rootState
		}, param) {
			const queryRef = db.collection('assetHistories')
				.where("assetId", "==", param.assetId);
			return queryRef.get().then(function (querySnapshot) {
				let assetHistories = {}
				querySnapshot.forEach(function (doc) {
					let assetHistory = doc.data();
					assetHistory.id = doc.id;
					assetHistories[doc.id] = assetHistory;
				});
				commit('SET_ASSET_HISTORIES', assetHistories);
				return assetHistories;
			}).catch(function (error) {
				return error;
			});
		}
	},

	getters: {
		assetHistories(state) {
			if (!_.isEmpty(state.assetHistories)) {
				return state.assetHistories;
			}
			return {};
		}
	}
}
