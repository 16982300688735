import { db } from '@/config/firebase';
import _ from 'lodash';

export default {
    namespace: true,

    state: {
        companyAssetPools: {},
        currCompanyAssetPool: {},
    },

    mutations: {
        SET_COMPANY_ASSET_POOLS(state, companyAssetPools) {
            state.companyAssetPools = companyAssetPools
        },
        SET_CURR_COMPANY_ASSET_POOL(state, currCompanyAssetPool) {
            state.currCompanyAssetPool = currCompanyAssetPool
        }
    },

    actions: {
        setAllCompanyAssetPools(vuexContext, companyAssetPools) {
            vuexContext.commit('SET_ALL_COMPANY_ASSET_POOLS', companyAssetPools);
        },
        updateAllCompanyAssetPools(vuexContext, newCompanyAssetPools) {
			let companyAssetPools = vuexContext.getters.companyAssetPools;

			_.forEach(newCompanyAssetPools, (value, key) => {
				companyAssetPools[key] = value;
			});

			vuexContext.commit('SET_ALL_COMPANY_ASSET_POOLS', companyAssetPools);
		},

        async getCompanyAssetPool({ rootState }, param) {
            const queryRef = db.collection('companyAssetPools')
                .where('companyId', '==', param.companyId)
                .where('assetTypeId', '==', param.assetTypeId);

            return queryRef.get().then(querySnapshot => {
                let pool = {}
                querySnapshot.forEach(function (doc) {
                    pool = doc.data();
                    pool.id = doc.id;
                });
                return pool;
            }).catch(error => {
                return error;
            });
        },

        async getTaggedAsset({ rootState }, param) {
            let companyAssetPoolRef = db.collection('companyAssetPools')
                .doc(param.companyAssetPoolId);
            let taggedAssetRef = companyAssetPoolRef.collection('taggedAssets')
                .where('assetCode', '==', param.assetCode);
            return taggedAssetRef.get().then(querySnapshot => {
                let taggedAsset = {}
                querySnapshot.forEach(function (doc) {
                    taggedAsset = doc.data();
                    taggedAsset.id = doc.id;
                });
                return taggedAsset;
            });
        }
    },

    getters: {
        companyAssetPools(state) {
            if (!_.isEmpty(state.companyAssetPools)) {
                return state.companyAssetPools;
            }
            return {};
        },
        currCompanyAssetPool(state) {
            if (!_.isEmpty(state.currCompanyAssetPool)) {
                return state.currCompanyAssetPool;
            }
            return {};
        },
    }
}