import { db } from '@/config/firebase';
import _ from 'lodash';

export default {
	namespace: true,

	state: {
		connectionTypes: {}
	},

	mutations: {
		SET_ALL_CONNECTION_TYPES(state, connectionTypes) {
			state.connectionTypes = connectionTypes;
		}
	},

	actions: {
		async getAllConnectionTypes({
			commit,
			rootState
		}) {
			const connectionTypesColRef = db.collection('connectionTypes').orderBy("name");
			return connectionTypesColRef.get().then(querySnapshot => {
				let connectionTypes = {}
				querySnapshot.forEach(function (doc) {
					let connectionType = doc.data();
					connectionType['id'] = doc.id;
					connectionTypes[doc.id] = connectionType;
				});
				commit('SET_ALL_CONNECTION_TYPES', connectionTypes);
				return connectionTypes;
			})
		}
	},

	getters: {
		connectionTypes(state) {
			if (!_.isEmpty(state.connectionTypes)) {
				return state.connectionTypes;
			}
			return {};
		},
	}

}
