import { db } from '@/config/firebase';
import _ from 'lodash';

export default {
	namespace: true,

	state: {
		inventorySessions: {},
		currInventorySession: {},
		inventorySessionParams: {}
	},

	mutations: {
		SET_ALL_INVENTORY_SESSIONS(state, inventorySessions) {
			state.inventorySessions = inventorySessions;
		},
		SET_CURR_INVENTORY_SESSION(state, inventorySession) {
			state.currInventorySession = inventorySession;
		},
		SET_ALL_INVENTORY_SESSION_PARAMS(state, inventorySessionParams) {
			state.inventorySessionParams = inventorySessionParams;
		}
	},

	actions: {
		setAllInventorySessions(vuexContext, inventorySessions) {
			vuexContext.commit('SET_ALL_INVENTORY_SESSIONS', inventorySessions);
		},
		updateAllInventorySessions(vuexContext, newInventorySessions) {
			let inventorySessions = vuexContext.getters.inventorySessions;

			_.forEach(newInventorySessions, (value, key) => {
				inventorySessions[key] = value;
			});

			vuexContext.commit('SET_ALL_INVENTORY_SESSIONS', inventorySessions);
		},

		async verifyOnGoingInventorySession({ rootState }, storageLocationId) {
			const queryRef = db
				.collection('inventorySessions')
				.where('status', '==', 'On-Going')
				.where('storageLocationId', '==', storageLocationId);
			return queryRef
				.get()
				.then((querySnapshot) => {
					return querySnapshot.empty;
				})
				.catch((error) => {
					return error;
				});
		},
	},

	getters: {
		inventorySessions(state) {
			if (!_.isEmpty(state.inventorySessions)) {
				return state.inventorySessions;
			}
			return {};
		},
		inventorySessionParams(state) {
			if (state.inventorySessionParams.selInventorySession) {
				return state.inventorySessionParams;
			}
			return {};
		},
		currInventorySession(state) {
			if (state.currInventorySession.sessionId) {
				return state.currInventorySession;
			}
			return {};
		}
	}
};
