import firebase from 'firebase/app';


export const LocationUtil = {
    getLatitude: (geoaddress) => {
        let latitude = 0;

        if (geoaddress.latitude) {
            latitude = parseFloat(geoaddress.latitude);
        }

        if (geoaddress._latitude) {
            latitude = parseFloat(geoaddress._latitude);
        }

        if (geoaddress._lat) {
            latitude = parseFloat(geoaddress._lat);
        }

        return latitude;
    },
    getLongitude: (geoaddress) => {
        let longitude = 0;

        if (geoaddress.longitude) {
            longitude = parseFloat(geoaddress.longitude);
        }

        if (geoaddress._longitude) {
            longitude = parseFloat(geoaddress._longitude);
        }

        if (geoaddress._long) {
            longitude = parseFloat(geoaddress._long);
        }

        return longitude;
    },
    getGeoaddress: (geoaddress) => {
        // Note:
        // Since the geoaddress passed from the mobile is null,
        // we need to set the geopoint manually here in the website
        let latitude = 0.0;
        let longitude = 0.0;

        if (geoaddress.latitude) {
            latitude = parseFloat(geoaddress.latitude);
        }

        if (geoaddress.longitude) {
            longitude = parseFloat(geoaddress.longitude);
        }

        if (geoaddress._latitude) {
            latitude = parseFloat(geoaddress._latitude);
        }

        if (geoaddress._longitude) {
            longitude = parseFloat(geoaddress._longitude);
        }

        if (geoaddress._lat) {
            latitude = parseFloat(geoaddress._lat);
        }

        if (geoaddress._long) {
            longitude = parseFloat(geoaddress._long);
        }

        if (_.isEmpty(geoaddress)) {
            latitude = parseFloat(0);
            longitude = parseFloat(0);
        }

        return new firebase.firestore.GeoPoint(parseFloat(latitude), parseFloat(longitude));
    },
}