// Util
import { DateUtil } from '@/utils/dateutil';
import { LocationUtil } from "@/utils/locationUtil";

// Others
import { db } from '@/config/firebase';
import _ from 'lodash';

export default {
	namespace: true,

	state: {
		storageLocations: {},
		connectedStorageLocations: {},
		currStorageLocation: {},
	},

	mutations: {
		SET_ALL_STORAGE_LOCATIONS(state, storageLocations) {
			state.storageLocations = storageLocations
		},
		SET_CONNECTED_STORAGE_LOCATIONS(state, storageLocations) {
			state.connectedStorageLocations = storageLocations;
		},
		SET_CURR_STORAGE_LOCATION(state, storageLocation) {
			state.currStorageLocation = storageLocation
		},
	},

	actions: {
		setAllStorageLocations(vuexContext, storageLocations) {
			vuexContext.commit('SET_ALL_STORAGE_LOCATIONS', storageLocations);
		},
		setAllConnectedStorageLocations(vuexContext, storageLocations) {
			vuexContext.commit('SET_CONNECTED_STORAGE_LOCATIONS', storageLocations);
		},
		updateAllStorageLocations(vuexContext, newStorageLocations) {
			let storageLocations = vuexContext.getters.storageLocations;

			_.forEach(newStorageLocations, (value, key) => {
				storageLocations[key] = value;
			});

			vuexContext.commit('SET_ALL_STORAGE_LOCATIONS', storageLocations);
		},

		async addStorageLocation({
			rootState
		}, param) {

			let currUserId = rootState.auth.email;
			let newStorageLocation = {
				name: param.name,
				description: param.description ? param.description : '',
				company: param.company,
				companyId: param.companyId,
				address: param.address ? param.address : '',
				geoaddress: LocationUtil.getGeoaddress(param.geoaddress),
				isActive: 'true',
				dateCreated: DateUtil.getCurrentTimestamp(),
				createdBy: currUserId,
				dateUpdated: DateUtil.getCurrentTimestamp(),
				updatedBy: currUserId
			}

			return db.collection("storageLocations").add(newStorageLocation)
				.then((docRef) => {
					return {
						isSuccess: true,
						data: {
							id: docRef.id,
							...newStorageLocation
						}
					};
				}).catch((error) => {
					return {
						isSuccess: false,
						data: error
					};
				});
		},

		async updateStorageLocation({
			rootState,
			dispatch
		}, param) {

			let currUserId = rootState.auth.email;
			let selStorageLocation = {
				id: param.id,
				name: param.name,
				description: param.description ? param.description : '',
				company: param.company,
				companyId: param.companyId,
				address: param.address ? param.address : '',
				geoaddress: LocationUtil.getGeoaddress(param.geoaddress),
				isActive: param.isActive,
				dateCreated: param.dateCreated,
				createdBy: param.createdBy,
				dateUpdated: DateUtil.getCurrentTimestamp(),
				updatedBy: currUserId
			}

			// Get a new write batch
			let batch = db.batch();

			// update storage location data
			const storageLocationRef = db.collection("storageLocations").doc(param.id);
			batch.set(storageLocationRef, selStorageLocation);

			// get list of connections ids where selStorageLocation company as the connected company
			let connStorageLocationsCons = await dispatch('getConnectionsByConnectedCompany', {
				companyId: param.companyId
			});

			_.forEach(connStorageLocationsCons, con => {

				let newConnStorageLocations = {};

				_.forEach(con.storageLocations, conLocation => {
					let connStorageLocation = {};
					if (conLocation.id === param.id) {
						connStorageLocation = {
							id: conLocation.id,
							name: param.name,
							address: param.address,
							geoaddress: LocationUtil.getGeoaddress(param.geoaddress),
							isIncluded: conLocation.isIncluded,
							isActive: param.isActive
						}
					} else {
						connStorageLocation = {
							id: conLocation.id,
							name: conLocation.name,
							address: conLocation.address,
							geoaddress: LocationUtil.getGeoaddress(conLocation.geoaddress),
							isIncluded: conLocation.isIncluded,
							isActive: conLocation.isActive
						}
					}
					newConnStorageLocations[conLocation.id] = connStorageLocation;
				});

				// Update storageLocations in connections
				const connectedCompanyRef = db.collection('connections').doc(con.id);
				batch.update(connectedCompanyRef, {
					storageLocations: newConnStorageLocations
				});
			});

			// Commit the batch
			return batch.commit().then(() => {
				return {
					isSuccess: true,
					data: selStorageLocation
				};
			}).catch((error) => {
				return {
					isSuccess: false,
					data: error
				};
			});
		}
	},

	getters: {
		storageLocations(state) {
			if (!_.isEmpty(state.storageLocations)) {
				return state.storageLocations;
			}
			return {};
		},
		connectedStorageLocations(state) {
			if (!_.isEmpty(state.connectedStorageLocations)) {
				return state.connectedStorageLocations;
			}
			return {};
		},
		currStorageLocation(state) {
			if (!_.isEmpty(state.currStorageLocation)) {
				return state.currStorageLocation;
			}
			return {};
		}
	}
}
