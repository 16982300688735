export default {
    namespaced: true,
    state: {
        isDialogOpened: false,
        dispatch: {},
    },
    getters: {
        getDialogState(state) {
            return state.isDialogOpened;
        },
        getDispatchDetails(state) {
            return state.dispatch;
        },
    },
    mutations: {
        TOGGLE_DIALOG(state) {
            state.isDialogOpened = !state.isDialogOpened;
            if (state.isDialogOpened === false) {
                state.dispatch = {};
            }
        },
        SET_DISPATCH(state, dispatch) {
            state.dispatch = dispatch;
        },
    }
}