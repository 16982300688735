export default {
	currEnv: 'uat3',
	timeout: 1000,
	view: {
		ADMIN: "Admin",
		COMPANY: "Company"
	},
	requestSource: {
		CMS: 'CMS',
		DISPATCH_RECEIPT: 'Dispatch Receipt',
		INVENTORY: 'Inventory',
		STICKER_PRINTER: 'Sticker Printer'
	},
	firebaseAuthLink: 'https://www.googleapis.com/identitytoolkit/v3/relyingparty/verifyPassword?key=',
	googleMapKey: {
		dev: 'AIzaSyDHQ1L7lktr-nRkvOotd5KQoNuWWGl0C3A',
		qa: 'AIzaSyBmY7C3r1vC00UdTTjsDT9fIZfHtGKOo7k',
		uat: 'AIzaSyBNEF4JhnpQFXbGqeKnQZ79PiJCC1KMGQI',
		uat2: 'AIzaSyAyUCDCK3zaMGcz2NgKmmb5ae9OGZ0N_io',
		uat3: 'AIzaSyAaKIr5DVtA53afMaJeg7pAbgrytTyXvx4',
		preprod: 'AIzaSyAmIDSCh20k02cqZQKENn49cQ9zuXjoQFM',
		prod: 'AIzaSyDugL6fUgAwLci_6GcDAvMIT675HoBfIUk',
		pjl: 'AIzaSyBH9P5hg8WPQZNmpUPCEmslV8gH8ReLGWA'
	},
	adminAccount: {
		TAWI_SUPPORT: 'support@tawitech.ph'
	},
	managerRole: 'Manager',
	supervisorRole: 'Supervisor',
	viewerRole: 'Viewer',
	superAdminRole: 'Super Admin',
	passwordRegex: /[A-Za-z0-9<>()\.,\"\'=:;@$%*?!&_\-+|]{8,}/,
	passwordErrorMsg: 'Password must have at least eight (8) characters and should contain at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character',
	maxBatchSize: 400,
	companyTypes: [{
		value: null,
		text: ' - Please select - '
	},
	{
		value: 'Asset User',
		text: 'Asset User'
	},
	{
		value: 'Asset Service Provider',
		text: 'Asset Service Provider'
	},
	{
		value: 'Hybrid',
		text: 'Hybrid'
	}
	],
	assetStatus: [{
		value: null,
		text: ' - Please select - '
	},
	{
		value: 'Inactive',
		text: 'Inactive'
	},
	{
		value: 'Stored',
		text: 'Stored'
	}, {
		value: 'In-Transit',
		text: 'In-Transit'
	}, {
		value: 'Lost',
		text: 'Lost'
	}
	],
	changeLocationPurpose: [{
		value: null,
		text: ' - Please select - '
	},
	{
		value: 'Wrong Input',
		text: 'Wrong Input'
	},
	{
		value: 'Unscanned Assets',
		text: 'Unscanned Assets'
	}, {
		value: 'Others',
		text: 'Others'
	}
	],
	dispatchStatus: [{
		value: null,
		text: ' - Please select - '
	},
	{
		value: 'Draft',
		text: 'Draft'
	},
	{
		value: 'In-Transit',
		text: 'In-Transit'
	},
	{
		value: 'Receiving',
		text: 'Receiving'
	},
	{
		value: 'Received',
		text: 'Received'
	}, {
		value: 'Cancelled',
		text: 'Cancelled'
	}, {
		value: 'Pending',
		text: 'Pending'
	}
	],
	receiptStatus: [
		{
			value: 'In-Transit',
			text: 'In-Transit'
		},
		{
			value: 'Receiving',
			text: 'Receiving'
		},
		{
			value: 'Received',
			text: 'Received'
		},
		{
			value: 'Pending',
			text: 'Pending'
		}
	],
	inventorySessionStatus: [{
		value: null,
		text: ' - Please select - '
	},
	{
		value: 'Draft',
		text: 'Draft'
	},
	{
		value: 'On-Going',
		text: 'On-Going'
	}, {
		value: 'Done',
		text: 'Done'
	}, {
		value: 'Cancelled',
		text: 'Cancelled'
	}
	],
	assetConditionStatus: [
		{
			value: null,
			text: ' - Please select - '
		},
		{
			value: 'Good',
			text: 'Good'
		},
		{
			value: 'Damaged',
			text: 'Damaged'
		}
	],
	assetHistoryOperation: {
		CREATE_NEW_ASSET: 'CREATE_NEW_ASSET',
		ACTIVATE_ASSET: 'ACTIVATE_ASSET',
		CHANGE_ASSET_LOCATION: 'CHANGE_ASSET_LOCATION',
		MARK_ASSET_AS_LOST: 'MARK_ASSET_AS_LOST',
		DISPATCH_ASSET: 'DISPATCH_ASSET',
		CANCEL_DISPATCH_ASSET: 'CANCEL_DISPATCH_ASSET',
		RECEIVE_ASSET: 'RECEIVE_ASSET',
		IMPORT_ASSET: 'IMPORT_ASSET',
		ASSET_INVENTORY: 'ASSET_INVENTORY'
	},
	assetTagging: {
		DEFAULT: 'Default',
		CUSTOM: 'Custom'
	},
	assetIssuanceStatus: {
		ON_GOING: 'On-Going',
		RETURNED: 'Returned',
		CANCELLED: 'Cancelled'
	},
	statusOptions: [
		{ text: 'Active', value: "true" },
		{ text: 'Inactive', value: "false" },
	],
	deviceTypeOptions: [
		{ text: ' - Please select - ', value: null },
		{ text: 'Mobile', value: "mobile" },
		{ text: 'Web', value: "web" },
	],
	statusOptionsWithDefault: [
		{ text: ' - Please select - ', value: null },
		{ text: 'Active', value: "true" },
		{ text: 'Inactive', value: "false" },
	],
	requestStatusOptions: [
		{ text: ' - Please select - ', value: null },
		{ text: 'Open', value: 'Open' },
		{ text: 'Approved', value: 'Approved' },
		{ text: 'Rejected', value: 'Rejected' },
	],
	assetTaggingOptions: [
		{ text: 'Default', value: "Default" },
		{ text: 'Custom', value: "Custom" },
	],
	companyDefaultValue: {
		id: null,
		isActive: null,
		name: ' - Please select - ',
		type: null,
		brand: null
	},
	industryDefaultValue: {
		value: null,
		text: ' - Please select - '
	},
	userTypeDefaultValue: {
		value: null,
		text: ' - Please select - '
	},
	storageLocationDefaultValue: {
		id: null,
		name: ' - Please select - ',
		geoaddress: null,
		companyId: null,
		text: ' - Please select - '
	},
	transportationDefaultValue: {
		id: null,
		name: ' - Please select - ',
		company: null,
		companyId: null,
		text: ' - Please select - '
	},
	transportationOthersValue: {
		id: null,
		name: ' - Others - ',
		company: null,
		companyId: null,
		text: ' - Please select - '
	},
	assetTypeDefaultValue: {
		id: null,
		name: ' - Please select - ',
		origin: null,
		originId: null,
		text: ' - Please select - '
	},
	assetTypeCodeDefaultValue: {
		id: null,
		name: ' - Please select - ',
		prefix: null,
		text: ' - Please select - '
	},
	userDefaultValue: {
		id: null,
		name: ' - Please select - ',
		companyId: null,
		type: null,
		text: ' - Please select - '
	},
	dispatchCreationSource: {
		DISPATCH: 'Dispatch',
		RECEIPT: 'Receipt',
		PUSHPULL: 'PushPull'
	},
	dispatchInputAssetLogDefaultValue: {
		dispatch: { scan: [], manual: [], auto: [] },
		receipt: { scan: [], manual: [], auto: [] }
	},
	inputAssetLogDefaultValue: { scan: [], manual: [], auto: [] },
	notificationType: {
		LOCKED_ACCOUNT: 'LOCKED_ACCOUNT',
		NEW_COMPANY: 'NEW_COMPANY',
		DISPATCH_DEPLOYED: 'DISPATCH_DEPLOYED',
		DISPATCH_RECEIVED: 'DISPATCH_RECEIVED',
		DISPATCH_IN_TRANSIT: 'DISPATCH_IN_TRANSIT',
		CHANGE_LOCATION: 'CHANGE_LOCATION'
	},
	permissionsDefaultValues: {
		cms: true,
		inventory: true,
		dispatch: true,
		sticker: true,
	},
	userTypes: [
		{ value: null, text: ' - Please select - ' },
		{ value: 'Manager', text: 'Manager' },
		{ value: 'Supervisor', text: 'Supervisor' },
		{ value: 'Scanner', text: 'Scanner' },
		{ value: 'Viewer', text: 'Viewer' },
	],
};
