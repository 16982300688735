// Util
import { DateUtil } from '@/utils/dateutil';

// Others
import { db } from '@/config/firebase';
import _ from 'lodash';


export default {
	namespace: true,

	state: {
		transportations: {},
		currTransportation: {},
		companyTransportations: {}
	},

	mutations: {
		SET_ALL_TRANSPORTATIONS(state, transportations) {
			state.transportations = transportations
		},
		SET_CURR_TRANSPORTATION(state, transportation) {
			state.currTransportation = transportation
		},
		SET_ACTIVE_STATE(state, payload) {
			if (payload.isCompany) {
				state.companyTransportations[payload.id]['isActive'] = payload.state;
			} else {
				state.transportations[payload.id]['isActive'] = payload.state;
			}
		}
	},

	actions: {
		setAllTransportations(vuexContext, transportations) {
			vuexContext.commit('SET_ALL_TRANSPORTATIONS', transportations);
		},
		updateAllTransportations(vuexContext, newTransportations) {
			let transportations = vuexContext.getters.transportations;

			_.forEach(newTransportations, (value, key) => {
				transportations[key] = value;
			});

			vuexContext.commit('SET_ALL_TRANSPORTATIONS', transportations);
		},

		async addTransportation({
			rootState
		}, param) {

			let currUserId = rootState.auth.email;
			let newTransportation = {
				plateNo: param.plateNo,
				description: param.description,
				company: param.company,
				companyId: param.companyId,
				isActive: param.isActive,
				dateCreated: DateUtil.getCurrentTimestamp(),
				createdBy: currUserId,
				dateUpdated: DateUtil.getCurrentTimestamp(),
				updatedBy: currUserId
			}

			return db.collection("transportations").add(newTransportation)
				.then((docRef) => {
					newTransportation.id = docRef.id;
					return {
						isSuccess: true,
						data: newTransportation
					};
				}).catch((error) => {
					return {
						isSuccess: false,
						data: error
					};
				});
		},

		async updateTransportation({
			rootState
		}, param) {
			let currUserId = rootState.auth.email;
			let selTransportation = {
				id: param.id,
				plateNo: param.plateNo,
				description: param.description,
				company: param.company,
				companyId: param.companyId,
				isActive: param.isActive,
				dateCreated: param.dateCreated,
				createdBy: param.createdBy,
				dateUpdated: DateUtil.getCurrentTimestamp(),
				updatedBy: currUserId
			}

			return db.collection("transportations").doc(param.id).set(selTransportation)
				.then(() => {
					return {
						isSuccess: true,
						data: selTransportation
					};
				}).catch((error) => {
					return {
						isSuccess: false,
						data: error
					};
				});
		},

		async fetchDispatchesByPlateNo({ rootState }, plateNo) {
			let queryRef = db.collection('dispatches');
			queryRef = queryRef.where("transportation.plateNo", "==", plateNo);

			return queryRef.get()
				.then(querySnapshot => {
					let items = [];
					querySnapshot.forEach(item => items.push(item.data()));
					return items;
				}).catch(error => {
					return error;
				})
		}
	},

	getters: {
		transportations(state) {
			if (!_.isEmpty(state.transportations)) {
				return state.transportations;
			}
			return {};
		},
		currTransportation(state) {
			if (!_.isEmpty(state.currTransportation)) {
				return state.currTransportation;
			}
			return {};
		},
		companyTransportations(state) {
			if (!_.isEmpty(state.companyTransportations)) {
				return state.companyTransportations;
			}
			return {};
		}
	}
}
