import { db } from '@/config/firebase';
import _ from 'lodash';

export default {
	namespace: true,

	state: {
		assets: {},
		currAsset: {}
	},

	mutations: {
		SET_ALL_ASSETS(state, assets) {
			state.assets = assets;
		},
		SET_CURR_ASSET(state, asset) {
			state.currAsset = asset;
		}
	},

	actions: {
		setAllAssets(vuexContext, assets) {
			vuexContext.commit('SET_ALL_ASSETS', assets);
		},
		updateAllAssets(vuexContext, newAssets) {
			let assets = vuexContext.getters.assets;

			_.forEach(newAssets, (value, key) => {
				assets[key] = value;
			});

			vuexContext.commit('SET_ALL_ASSETS', assets);
		},

		async getAssetById({
			_commit,
			rootState
		}, assetCode) {
			const assetRef = db.collection('assets').doc(assetCode);
			return assetRef.get().then(result => {
				let asset = null;
				if (result.exists) {
					asset = result.data();
					asset['id'] = result.id;
				}
				return asset;
			});
		},

		async getAssetsByAssetCode({
			rootState
		}, param) {
			let assetCodes = param.assetCodes;

			let dbRef = [];

			for (const assetCode of assetCodes) {
				let assetRef = db.collection('assets').doc(assetCode).get();
				dbRef.push(assetRef);
			}

			return Promise.all(dbRef).then(docs => {
				let assetsObj = {};
				docs.forEach(doc => {
					let asset = doc.data();
					if (asset) {
						assetsObj[asset.assetCode] = asset;
					}
				});

				return Promise.all([assetsObj]);
			});
		},

		async getAssetsByStatus({
			_commit,
			rootState
		}, param) {
			// retrieve params
			let status = param.status;
			let assetType = param.assetType;
			let assetCode = param.assetCode;
			let companyId = param.companyId;
			let storageLocationId = param.storageLocationId;

			let queryRef = db.collection('assets');

			if (status === 'Stored') {
				queryRef = queryRef.where('status', '==', 'Stored')
					.where('currentLocation.companyId', '==', companyId)
					.where('currentLocation.storageLocationId', '==', storageLocationId)
			} else if (status === 'Inactive') {
				queryRef = queryRef.where('status', '==', 'Inactive')
					.where('originId', '==', companyId);
			} else if (status === 'Lost') {
				queryRef = queryRef.where('status', '==', 'Lost')
					.where('originId', '==', companyId);
			}

			if (assetType !== ' - Please select - ') {
				queryRef = queryRef.where('assetType', '==', assetType);
			}

			if (assetCode && assetCode.length > 0) {
				queryRef = queryRef.where('assetCode', '==', assetCode);
			}

			return queryRef
				.get()
				.then((querySnapshot) => {
					let assets = {};
					querySnapshot.forEach(function (doc) {
						let asset = doc.data();
						asset['id'] = doc.id;
						assets[doc.id] = asset;
					});
					return assets;
				})
				.catch((error) => {
					return error;
				});
		},
	},

	getters: {
		assets(state) {
			if (!_.isEmpty(state.assets)) {
				return state.assets;
			}
			return {};
		},
		currAsset(state) {
			if (!_.isEmpty(state.currAsset)) {
				return state.currAsset;
			}
			return {};
		},
	}
};
