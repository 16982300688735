import config from '@/config/env-constants';
import _ from 'lodash';


function allowManagerAccount(user, users) {
	let allow = true;

	let existingManagerAccount = _.filter(users, o => {
		return o.isActive === 'true'
			&& user.companyId === o.companyId
			&& o.type === config.managerRole;
	});

	if (user.type === config.managerRole && !_.isEmpty(existingManagerAccount) && existingManagerAccount[0].id !== user.id) {
		allow = false
	}

	return allow;

}

function exceedMaximum(company, users, currUser) {
	let maxNo = company.noOfUsers ? company.noOfUsers : 0;
	if (!_.isEmpty(users)) {
		let activeUsers = _.filter(users, o => {
			return o.isActive === 'true' && company.id === o.companyId;
		});

		let activeUserIds = _.map(activeUsers, 'id');
		if (currUser && activeUserIds.includes(currUser.id)) {
			// skip if the currUser is already part of current active users
			return false;
		} else {
			return _.size(activeUsers) >= maxNo;
		}
	}
	return false;
}

function isSuperAdmin(userEmailAddress) {
	return userEmailAddress === config.adminAccount.TAWI_SUPPORT;
}

async function getDefaultCompanyAccess(user, allCompaniesObj) {
	let companyAccess = [];

	// default access
	let currCompanyObj = allCompaniesObj[user.companyId];
	companyAccess.push({
		id: currCompanyObj.id,
		name: currCompanyObj.name,
		description: currCompanyObj.description
	});

	if (user.type === config.managerRole) {
		// Add access from other sibling companies
		let siblingCompaniesObj = getChildCompanies(allCompaniesObj, user.companyId);

		if (!_.isEmpty(siblingCompaniesObj)) {
			_.forEach(siblingCompaniesObj, company => {
				companyAccess.push({
					id: user.companyId,
					name: user.company
				});
			});
		}
	}

	return companyAccess;
}

function getChildCompanies(companiesObj, companyId) {
	return _.keyBy(_.filter(companiesObj, o => {
		return o.parentCompanyId === companyId;
	}), 'id');
}

function cleanupFields(user) {
	let cleanedDispatch = Object.assign({}, user);

	delete cleanedDispatch['_showDetails'];

	return cleanedDispatch;
}

function hasCompanyAccess(companiesObj, companyId) {
	let companyObj = _.find(companiesObj, (o) => { return o.id === companyId });
	return companyObj && !_.isEmpty(companyObj);
}

export const UserUtil = {
	allowManagerAccount,
	exceedMaximum,
	isSuperAdmin,
	getDefaultCompanyAccess,
	cleanupFields,
	hasCompanyAccess
}
